// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-fixed-js": () => import("./../../../src/pages/fixed.js" /* webpackChunkName: "component---src-pages-fixed-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-percentage-js": () => import("./../../../src/pages/percentage.js" /* webpackChunkName: "component---src-pages-percentage-js" */),
  "component---src-pages-standard-ratio-tool-js": () => import("./../../../src/pages/standard-ratio-tool.js" /* webpackChunkName: "component---src-pages-standard-ratio-tool-js" */)
}

