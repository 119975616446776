import React, { createContext, useContext, useState } from "react";

const GridVisibilityContext = createContext({});

export function useGridVisibilityState() {
	return useContext(GridVisibilityContext);
}

export function GridVisibilityProvider({ visible = false, children }) {
	const [visibility, setVisibility] = useState(visible);

	return (
		<GridVisibilityContext.Provider value={{ visibility, setVisibility }}>
			{children}
		</GridVisibilityContext.Provider>
	);
}
